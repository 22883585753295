@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 900;
  src: local('Noto Sans Korean Black'), 
       url('./fonts/NotoSansKR-Black.ttf') format('woff2'),
       url('./fonts/NotoSansKR-Black.ttf') format('woff'),
       url('./fonts/NotoSansKR-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: local('Noto Sans Korean Bold'), 
       url('./fonts/NotoSansKR-Bold.ttf') format('woff2'),
       url('./fonts/NotoSansKR-Bold.ttf') format('woff'),
       url('./fonts/NotoSansKR-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 800;
  src: local('Noto Sans Korean ExtraBold'), 
       url('./fonts/NotoSansKR-ExtraBold.ttf') format('woff2'),
       url('./fonts/NotoSansKR-ExtraBold.ttf') format('woff'),
       url('./fonts/NotoSansKR-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 200;
  src: local('Noto Sans Korean ExtraLight'), 
       url('./fonts/NotoSansKR-ExtraLight.ttf') format('woff2'),
       url('./fonts/NotoSansKR-ExtraLight.ttf') format('woff'),
       url('./fonts/NotoSansKR-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 300;
  src: local('Noto Sans Korean Light'), 
       url('./fonts/NotoSansKR-Light.ttf') format('woff2'),
       url('./fonts/NotoSansKR-Light.ttf') format('woff'),
       url('./fonts/NotoSansKR-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500;
  src: local('Noto Sans Korean Medium'), 
       url('./fonts/NotoSansKR-Medium.ttf') format('woff2')
       url('./fonts/NotoSansKR-Medium.ttf') format('woff')
       url('./fonts/NotoSansKR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: local('Noto Sans Korean Regular'), 
       url('./fonts/NotoSansKR-Regular.ttf') format('woff2'),
       url('./fonts/NotoSansKR-Regular.ttf') format('woff'),
       url('./fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 600;
  src: local('Noto Sans Korean SemiBold'), 
       url('./fonts/NotoSansKR-SemiBold.ttf') format('woff2'),
       url('./fonts/NotoSansKR-SemiBold.ttf') format('woff'),
       url('./fonts/NotoSansKR-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 100;
  src: local('Noto Sans Korean Thin'), 
       url('./fonts/NotoSansKR-Thin.ttf') format('woff2'),
       url('./fonts/NotoSansKR-Thin.ttf') format('woff'),
       url('./fonts/NotoSansKR-Thin.ttf') format('truetype');
}

body, button, input, textarea {
  font-family: 'Noto Sans KR', sans-serif;

}

body {
  margin: 0;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
